<template>
    <ChargeComponent :maxTime="maxTime" enablePenalties/>
</template>

<script>
    import ChargeComponent from "@/components/ChargeComponent";
    import { mapGetters } from "vuex"
    export default {
        components: {
            ChargeComponent
        },
        data(){
            return {
                maxTime: null,
                page: {
                    title: "chargingPageTitle",
                    description: null
                },
            }
        },
        computed: {
            ...mapGetters({
                selectedPin: "bookingDialog/selectedPin",
                bookedPins: "bookingDialog/bookedPins"
            }),
        },
        mounted(){
            if(this.bookedPins && this.bookedPins.length > 0){
                this.maxTime = this.bookedPins[0].chargeDuration
            }
        }
    }
</script>

<style scoped>
    .charger-wrapper {
        border: solid 1px #3c3c3c;
    }
    .charger-wrapper.disabled{
        background: #a1a1a1;
        color: white;
        border: solid 1px #3c3c3c;
    }
</style>